<template>
  <div id="app">
    <video src="./assets/FantasyGrowTentSeason2.mp4" type="video/mp4" width="550" controls autoplay></video>
    <HelloWorld msg="Fantasy Grow Tent"/>
    <!-- <iframe width="400" height="300" src="https://www.instagram.com/p/CRZejzDnM6h/embed/" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> -->
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
